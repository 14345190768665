import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Checkbox.module.css';

const Checkbox = ({ label, checked, onChange, disabled }) => {
  return (
    <label className={styles.container}>
      <input
        checked={checked}
        className={styles.input}
        type="checkbox"
        onClick={e => {
          onChange(e.target.checked);
        }}
        onChange={() => {}}
        disabled={disabled}
      />
      <span className={styles.label}>{label || ' '}</span>
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.any,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
