import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as styles from './QuantityPicker.module.css';

const QuantityPicker = ({ value, max, onChange, disabled }) => {
  const [maxReach, handleMaxReach] = useState(false);

  const onAdd = () => {
    const nextVal = value + 1;
    if (nextVal <= max) {
      if (maxReach) handleMaxReach(false);
      onChange(nextVal);
    } else {
      if (!maxReach) {
        handleMaxReach(true);
        setTimeout(() => {
          handleMaxReach(false);
        }, 2000);
      }
    }
  };

  const onRemove = () => {
    const nextVal = value - 1;
    if (maxReach) handleMaxReach(false);
    if (nextVal >= 1) {
      onChange(nextVal);
    }
  };

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={onRemove} disabled={disabled}>
        -
      </button>
      <div className={styles.qty}>{value}</div>
      <button className={styles.button} onClick={onAdd} disabled={disabled}>
        +
      </button>

      {maxReach && (
        <div className={styles.max}>Max {value} E-Bike disponibili</div>
      )}
    </div>
  );
};

QuantityPicker.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default QuantityPicker;
