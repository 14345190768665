import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Trash2 } from 'react-feather';
import ClipLoader from 'react-spinners/ClipLoader';

import { getAvailabilities } from '@api';
import { CartContext } from '@context/Cart.context';
import {
  setLocalStorageCart,
  getPrice,
  formatTimeLabel,
  insertSlash,
  getLocalStorageCart,
  composeCart,
} from '@utils';

import CheckoutButton from '@components/CheckoutButton/CheckoutButton';
import QuantityPicker from '@components/QuantityPicker/QuantityPicker';
import Checkbox from '@components/Checkbox/Checkbox';
import Button from '@components/Button/Button';
import Toast from '@components/Toast/Toast';

import * as styles from './Cart.module.css';

const Cart = ({ en }) => {
  const { cart, handleCart } = useContext(CartContext);
  let localCart = getLocalStorageCart();
  const cartToUse = !localCart
    ? cart
    : cart.length === 0
    ? JSON.parse(localCart)
    : cart;

  const [availabilitiesLoading, handleAvailabilitiesLoading] = useState(false);
  const [availabilitiesError, handleAvailabilitiesError] = useState(false);
  const [availabilities, handleAvailabilities] = useState(null);
  const [availabilitiyError, handleAvailabilityError] = useState(false);
  const [checkoutError, handleCheckoutError] = useState(false);
  const [check, handleCheck] = useState(false);

  const total = cartToUse
    .reduce((acc, o) => acc + parseInt(o.price, 10), 0)
    .toFixed(2);

  const onCheckoutError = error => {
    if (error === 'availability') {
      handleAvailabilityError(true);
    } else if (error === 'checkout') {
      handleCheckoutError(true);
    }
  };

  const onDelete = (id, time, size) => {
    const clonedCart = JSON.parse(JSON.stringify(cartToUse));
    const newCart = clonedCart.filter(el => {
      const isToDelete = el.id === id && el.time === time && el.size === size;
      return !isToDelete;
    });
    handleCart(composeCart(newCart));
    setLocalStorageCart(composeCart(newCart));
  };

  const onQuantityChange = (product, id, time, size, qty) => {
    const clonedCart = JSON.parse(JSON.stringify(cartToUse));
    const newCart = clonedCart.map(el => {
      if (el.id === id && el.time === time && el.size === size) {
        el.quantity = qty;
        el.price = getPrice(product, time, qty);
      }
      return el;
    });

    handleCart(composeCart(newCart));
    setLocalStorageCart(composeCart(newCart));
  };

  const get = async () => {
    handleAvailabilitiesLoading(true);
    try {
      const a = await getAvailabilities();
      const avail = a && a.data ? a.data.availabilities : [];
      handleAvailabilities(avail);
    } catch (error) {
      handleAvailabilitiesError(true);
    }
    handleAvailabilitiesLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  const renderDetail = () => {
    return cartToUse.map((c, i) => {
      return (
        <div key={i} className={styles.detailRow}>
          <div className={styles.detailCol}>
            {c.product.name} {c.size !== 'KID' ? `| ${c.size}` : ''}{' '}
            <strong>x {c.quantity}</strong>
            <span className={styles.detailColDate}>{insertSlash(c.id)}</span>
          </div>
          <div className={`${styles.detailCol} ${styles.detailColPrice}`}>
            {c.price} &euro;
          </div>
        </div>
      );
    });
  };

  const renderCartProducts = () => {
    if (!availabilities) return null;
    if (cartToUse.length === 0) {
      return (
        <div className={styles.emptyCart}>
          <h3 className={styles.emptyCartTitle}>
            {!en ? 'Non ci sono E-Bike qui' : 'No E-Bikes here'}
          </h3>
          <p>
            <Link to={!en ? '/ebike' : '/en/ebike'}>
              {!en ? 'Scegli la tua preferita' : 'Choose your favourite'}
            </Link>{' '}
            {!en ? 'e torna qui!' : 'and come back here!'}
          </p>
        </div>
      );
    }

    return cartToUse.map((c, i) => {
      const image = require(`@static/img/${c.product.images[0]}`).default;
      const type = `${c.size.toLowerCase()}-${c.time}`;
      const filteredAvailabilities = availabilities.filter(
        el => el.id === c.id
      );
      let max = 0;
      if (filteredAvailabilities.length > 0) {
        const availailityType = filteredAvailabilities[0].data[type];
        if (availailityType) {
          max = availailityType;
        }
      }
      return (
        <div key={i} className={styles.card}>
          <div className={styles.cardImage}>
            <img src={image} alt={c.product.name} width="207" height="173" />
          </div>
          <div className={styles.cardDesc}>
            <h2 className={styles.cardTitle}>{c.product.name}</h2>
            <p>
              <strong>{!en ? 'DATA:' : 'DATE:'}</strong> {insertSlash(c.id)}
              <br />
              <strong>{!en ? 'TAGLIA:' : 'SIZE:'}</strong> {c.size}
              <br />
              <strong>{!en ? 'DURATA:' : 'DURATION:'}</strong>{' '}
              {formatTimeLabel(c.time)}
            </p>
            <div className={styles.cardPrice}>{c.price} &euro;</div>
          </div>
          <div className={styles.cardQuantity}>
            <QuantityPicker
              value={c.quantity}
              max={max}
              onChange={val => {
                onQuantityChange(c.product, c.id, c.time, c.size, val);
              }}
            />

            <a
              href="#delete"
              className={styles.deleteButton}
              onClick={() => {
                onDelete(c.id, c.time, c.size);
              }}
            >
              <Trash2 />
            </a>
          </div>
        </div>
      );
    });
  };

  const renderLabel = () => {
    const regolamento =
      require(`@static/pdf/regolamento-noleggio-ebike.pdf`).default;
    return (
      <span>
        {!en ? 'Accetta il' : 'Accept'}{' '}
        <a href={regolamento} target="_blank" rel="noreferrer">
          {!en ? 'Regolamento di noleggio' : 'Rental regulation'}
        </a>{' '}
        {!en ? 'e la' : 'and'}{' '}
        <a href="/privacy-policy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>{' '}
        {!en ? 'per procedere al pagamento.' : 'to proceed with the payment.'}
      </span>
    );
  };

  const isCartOk =
    !availabilitiesLoading && cartToUse.length > 0 && !availabilitiesError;

  return (
    <div className={styles.container}>
      <h2 className={styles.containerTitle}>
        {!en ? 'Il tuo carrello' : 'Your cart'}
      </h2>

      {availabilitiesLoading && (
        <div className={styles.loader}>
          <ClipLoader color="#093f98" loading />
        </div>
      )}

      {availabilitiesError && (
        <Toast type="error">
          <h3 className={styles.toastTitle}>
            {!en
              ? 'Ops, qualcosa è andato storto durante il controllo delle disponibilità!'
              : 'Oops, something went wrong while checking the availability!'}
          </h3>
          {!en ? 'Contattaci telefonicamente al' : 'Contact us by phone at'}{' '}
          <a href="tel:3394329988">339.4329988</a>{' '}
          {!en ? 'o via email' : 'or by email'}{' '}
          <a href="mailto:info@tizianobiellersport.com">
            info@tizianobiellersport.com
          </a>
        </Toast>
      )}

      {isCartOk && (
        <p className={styles.containerDesc}>
          {!en
            ? 'Controlla il tuo ordine, leggi il regolamento e procedi al pagamento.'
            : 'Check your order, read the regulation and proceed to payment.'}
          <br />
          {!en ? 'Hai dimenticato qualcosa?' : 'You forgot something?'}{' '}
          <Link to={!en ? '/ebike' : '/en/ebike'}>
            {!en ? 'Torna alle E-Bike' : 'Back to E-Bikes'}
          </Link>
        </p>
      )}

      {!availabilitiesLoading && !availabilitiesError && (
        <div className={styles.list}>{renderCartProducts()}</div>
      )}
      {isCartOk && (
        <div className={styles.detail}>
          {renderDetail()}
          <div className={`${styles.detailRow} ${styles.detailRowLast}`}>
            <div className={styles.detailCol}>
              <strong>{!en ? 'TOTALE' : 'TOTAL'}</strong>
            </div>
            <div className={`${styles.detailCol} ${styles.detailColPrice}`}>
              {total} &euro;
            </div>
          </div>
        </div>
      )}

      {isCartOk && (
        <div className={styles.acceptContainer}>
          <Checkbox
            label={renderLabel()}
            onChange={handleCheck}
            checked={check}
          />
        </div>
      )}

      {availabilitiyError && (
        <div className={styles.acceptContainer}>
          <Toast type="error">
            {!en
              ? 'Il numero di bici richiesto non è al momento disponibile. Le disponibilità devono essere variate durante il tuo acquisto.'
              : 'The requested number of bikes is currently not available. Availability must have changed during your purchase.'}
            <br />
            {!en
              ? 'Contattaci telefonicamente al'
              : 'Contact us by phone at'}{' '}
            <a href="tel:3394329988">339.4329988</a>{' '}
            {!en ? 'o via email' : 'or by email'}{' '}
            <a href="mailto:info@tizianobiellersport.com">
              info@tizianobiellersport.com
            </a>
          </Toast>
        </div>
      )}

      {checkoutError && (
        <div className={styles.acceptContainer}>
          <Toast type="error">
            {!en
              ? 'Ops, qualcosa è andato storto durante la procedura di checkout!'
              : 'Oops, something went wrong during the checkout process!'}
            <br />
            {!en
              ? 'Contattaci telefonicamente al'
              : 'Contact us by phone at'}{' '}
            <a href="tel:3394329988">339.4329988</a>{' '}
            {!en ? 'o via email' : 'or by email'}{' '}
            <a href="mailto:info@tizianobiellersport.com">
              info@tizianobiellersport.com
            </a>
          </Toast>
        </div>
      )}

      {isCartOk && (
        <div className={styles.buttons}>
          <Button
            type="secondary"
            className={styles.buttonLeft}
            onClick={() => {
              navigate(!en ? '/ebike' : '/en/ebike');
            }}
          >
            {!en ? 'TORNA ALLE E-BIKE' : 'BACK TO E-BIKES'}
          </Button>
          <CheckoutButton disabled={!check} onError={onCheckoutError} en={en} />
        </div>
      )}
    </div>
  );
};

Cart.propTypes = {};

export default Cart;
