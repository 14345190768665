import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { createCheckout } from '@api';
import { setLocalStorageCart, getLocalStorageCart } from '@utils';
import { checkCurrentAvailability } from '@utils/cart';
import { CartContext } from '@context/Cart.context';

import Button from '@components/Button/Button';

import getStripe from '@utils/stripe';

const CheckoutButton = ({ disabled, onError, en }) => {
  const { cart, handleCart } = useContext(CartContext);
  const [loading, handleLoading] = useState(false);
  let localCart = getLocalStorageCart();
  const cartToUse = !localCart
    ? cart
    : cart.length === 0
    ? JSON.parse(localCart)
    : cart;

  const cleanCart = () => {
    handleCart([]);
    setLocalStorageCart(null);
  };

  const fireError = error => {
    onError(error);
    handleLoading(false);
  };

  const onCheckout = async () => {
    if (!loading) {
      handleLoading(true);
      const checkCart = await checkCurrentAvailability(cartToUse);
      if (checkCart) {
        if (checkCart.status === 'KO') {
          fireError('availability');
        } else {
          try {
            const checkout = await createCheckout(cartToUse);
            try {
              const stripe = await getStripe(checkout.data.publishableKey);
              cleanCart();

              const { error } = await stripe.redirectToCheckout({
                sessionId: checkout.data.sessionId,
              });

              if (error) {
                fireError('checkout');
              }
            } catch (error) {
              fireError('checkout');
            }
          } catch (error) {
            fireError('checkout');
          }
        }
      }
    }
  };

  return (
    <Button
      onClick={onCheckout}
      disabled={disabled}
      loading={loading}
      style={{ minWidth: '250px', with: '100%' }}
    >
      {!en ? 'PROCEDI AL PAGAMENTO' : 'PAY NOW'}
    </Button>
  );
};

CheckoutButton.propTypes = {
  disabled: PropTypes.bool,
  onError: PropTypes.func,
};

export default CheckoutButton;
