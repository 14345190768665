import React from 'react';
// import PropTypes from 'prop-types';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import Cart from '@components/Cart/Cart';

const CartPage = ({ location }) => {
  const en = location.hash === '#en';

  return (
    <Layout location={location} map itinerari en={en}>
      <Seo
        title={
          !en
            ? 'Il tuo carrello E-Bike - Tiziano Bieller Sport'
            : 'Your E-Bike cart - Tiziano Bieller Sport'
        }
        lang={!en ? 'it' : 'en'}
      />
      <Cart en={en} />
    </Layout>
  );
};

CartPage.propTypes = {};

export default CartPage;
